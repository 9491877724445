<template>
  <div v-if="page" class="page">

    <div class="outer-container">
      <div class="inner-container">


        <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
          <router-link to="/experience" class="hover:text-white transition-colors">Experience</router-link>
          <span class="divider inline-block px-4">&gt;</span><span
            class="text-white">{{ page.elements.title.value }}</span>
        </div>


        <div class="hero mt-12 pb-16 md:pb-36 relative">
          <h1 class="max-w-7xl font-bold uppercase relative z-20">{{ page.elements.title.value }} - Day {{day.elements.day_number.value}}</h1>
          <router-link class="text-sm uppercase text-primary-500 mt-2 relative z-20" :to="`/experience/${page.elements.slug.value}`">Back to {{page.elements.title.value}}</router-link>
          <bleed-container bleed="left" class="hero-image-height -mt-8 relative z-10">

            <div class="bleed-wrapper relative z-20 h-full" v-if="page.elements.logo.value.length">
              <div class="logo-box bg-black p-6 md:p-16 lg:p-20 absolute right-0 -bottom-16 md:-bottom-36">
                <div class="logo relative p-8 w-40 h-40 md:w-64 md:h-64 lg:w-100 lg:h-100">
                  <div class="logo-img absolute left-8 top-8 right-8 bottom-8">
                    <img :src="page.elements.logo.value[0].url" :alt="`${page.elements.title.value} logo`"
                         class="absolute object-contain object-center left-0 top-0 w-full h-full">
                  </div>
                </div>
              </div>
            </div>
          </bleed-container>
        </div>


        <div class="main-content relative mt-12">
          <div class="row flex flex-col-reverse md:flex-row md:items-start">

            <div class="main flex-grow">

              <!-- intro text -->
              <div>
                <div class="intro-text" v-html="day.elements.intro_text.value"></div>
              </div>

              <!-- main text -->
              <div>
                <div class="main-text mt-12" v-html="day.elements.main_text.value"></div>
              </div>

              <!-- downloads -->
              <div class="mt-32">
                <div class="downloads -m-4 flex flex-row flex-wrap">
                  <div class="download p-4 w-full sm:w-1/2" v-if="day.elements.program.value.length">
                    <a :href="day.elements.program.value[0].url" target="_blank"
                       class="overflow-hidden flex flex-row w-full items-center border border-grey-100">
                      <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
                        <img src="/img/icon-file.png" alt="" class="block w-8">
                      </div>
                      <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis">Download program</div>
                    </a>
                  </div>
                  <div class="download p-4 w-full sm:w-1/2" v-if="day.elements.floorplan.value.length">
                    <a :href="day.elements.floorplan.value[0].url" target="_blank"
                       class="overflow-hidden flex flex-row w-full items-center border border-grey-100">
                      <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
                        <img src="/img/icon-file.png" alt="" class="block w-8">
                      </div>
                      <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis">Download floorplan</div>
                    </a>
                  </div>
                </div>
              </div>

              <!-- sessions -->

              <div class="mt-32" v-if="day.elements.sessions.value.length">
                <div class="sessions">
                  <div class="mt-20" v-for="session in day.elements.sessions.value" :key="session">
                    <bleed-container bleed="left">
                      <div class="session py-12 pr-12 lg:pr-20">
                        <div class="bleed-wrapper">

                          <div class="details mt-12 lg:flex lg:justify-between lg:items-center">
                            <h3 class="font-bold !mt-0">{{ modular_content[session].elements.title.value }}</h3>
                            <div class="detail-block bg-black lg:ml-12 mt-8 lg:mt-0 whitespace-nowrap px-8 py-4 lg:-mr-20">
                              <div class="item flex items-center">
                                <div class="icon">
                                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1562 4.03125C11.1562 5.53516 9.13281 8.1875 8.25781 9.28125C8.06641 9.55469 7.65625 9.55469 7.46484 9.28125C6.58984 8.1875 4.59375 5.53516 4.59375 4.03125C4.59375 2.22656 6.04297 0.75 7.875 0.75C9.67969 0.75 11.1562 2.22656 11.1562 4.03125ZM7.875 4.90625C8.47656 4.90625 8.96875 4.44141 8.96875 3.8125C8.96875 3.21094 8.47656 2.71875 7.875 2.71875C7.24609 2.71875 6.78125 3.21094 6.78125 3.8125C6.78125 4.44141 7.24609 4.90625 7.875 4.90625ZM11.6211 5.67188C11.6484 5.64453 11.6484 5.61719 11.6758 5.58984L14.8477 4.30469C15.2578 4.14062 15.75 4.44141 15.75 4.90625V12.3164C15.75 12.5898 15.5859 12.8359 15.3125 12.9453L11.375 14.5039V6.24609C11.457 6.05469 11.5391 5.86328 11.6211 5.67188ZM4.10156 5.67188C4.18359 5.86328 4.26562 6.05469 4.375 6.24609V13.1094L0.875 14.5039C0.464844 14.668 0 14.3672 0 13.9023V6.49219C0 6.21875 0.136719 5.97266 0.410156 5.89062L3.74609 4.55078C3.82812 4.93359 3.9375 5.31641 4.10156 5.67188ZM8.94141 9.82812C9.32422 9.36328 9.92578 8.59766 10.5 7.72266V14.5586L5.25 13.0547V7.72266C5.79688 8.59766 6.39844 9.36328 6.78125 9.82812C7.32812 10.5391 8.39453 10.5391 8.94141 9.82812Z" fill="#E8490F"/>
                                  </svg>
                                </div>
                                <span class="ml-2">{{ modular_content[session].elements.location.value }}</span>
                              </div>
                              <div class="item flex items-center">
                                <div class="icon">
                                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 14.75C3.11719 14.75 0 11.6328 0 7.75C0 3.89453 3.11719 0.75 7 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 7 14.75ZM6.34375 7.75C6.34375 7.96875 6.45312 8.1875 6.61719 8.29688L9.24219 10.0469C9.54297 10.2656 9.95312 10.1836 10.1445 9.88281C10.3633 9.58203 10.2812 9.17188 9.98047 8.95312L7.65625 7.42188V4.03125C7.65625 3.67578 7.35547 3.375 6.97266 3.375C6.61719 3.375 6.31641 3.67578 6.31641 4.03125L6.34375 7.75Z" fill="#E8490F"/>
                                  </svg>
                                </div>
                                <span class="ml-2">{{ dayjs.utc(modular_content[session].elements.date.value).tz(modular_content[session].elements.date.display_timezone).format('HH:mm') }} - {{ dayjs.utc(modular_content[session].elements.date.value).add(modular_content[session].elements.duration.value, 'minutes').tz(modular_content[session].elements.date.display_timezone).format('HH:mm') }}</span>
                              </div>
                              <div class="item flex items-center">
                                <div class="icon">
                                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.6875 1.8125V2.75H8.4375V1.8125C8.4375 1.31445 8.84766 0.875 9.375 0.875C9.87305 0.875 10.3125 1.31445 10.3125 1.8125V2.75H11.7188C12.4805 2.75 13.125 3.39453 13.125 4.15625V5.5625H0V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.8125C2.8125 1.31445 3.22266 0.875 3.75 0.875C4.24805 0.875 4.6875 1.31445 4.6875 1.8125ZM0 6.5H13.125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V6.5ZM1.875 9.78125C1.875 10.0449 2.08008 10.25 2.34375 10.25H3.28125C3.51562 10.25 3.75 10.0449 3.75 9.78125V8.84375C3.75 8.60938 3.51562 8.375 3.28125 8.375H2.34375C2.08008 8.375 1.875 8.60938 1.875 8.84375V9.78125ZM5.625 9.78125C5.625 10.0449 5.83008 10.25 6.09375 10.25H7.03125C7.26562 10.25 7.5 10.0449 7.5 9.78125V8.84375C7.5 8.60938 7.26562 8.375 7.03125 8.375H6.09375C5.83008 8.375 5.625 8.60938 5.625 8.84375V9.78125ZM9.84375 8.375C9.58008 8.375 9.375 8.60938 9.375 8.84375V9.78125C9.375 10.0449 9.58008 10.25 9.84375 10.25H10.7812C11.0156 10.25 11.25 10.0449 11.25 9.78125V8.84375C11.25 8.60938 11.0156 8.375 10.7812 8.375H9.84375ZM1.875 13.5312C1.875 13.7949 2.08008 14 2.34375 14H3.28125C3.51562 14 3.75 13.7949 3.75 13.5312V12.5938C3.75 12.3594 3.51562 12.125 3.28125 12.125H2.34375C2.08008 12.125 1.875 12.3594 1.875 12.5938V13.5312ZM6.09375 12.125C5.83008 12.125 5.625 12.3594 5.625 12.5938V13.5312C5.625 13.7949 5.83008 14 6.09375 14H7.03125C7.26562 14 7.5 13.7949 7.5 13.5312V12.5938C7.5 12.3594 7.26562 12.125 7.03125 12.125H6.09375ZM9.375 13.5312C9.375 13.7949 9.58008 14 9.84375 14H10.7812C11.0156 14 11.25 13.7949 11.25 13.5312V12.5938C11.25 12.3594 11.0156 12.125 10.7812 12.125H9.84375C9.58008 12.125 9.375 12.3594 9.375 12.5938V13.5312Z" fill="#E8490F"/>
                                  </svg>
                                </div>
                                <span class="ml-2">{{ dayjs.utc(modular_content[session].elements.date.value).tz(modular_content[session].elements.date.display_timezone).format('DD MMMM YYYY') }}</span>
                              </div>
                              <div class="item flex items-center" v-if="modular_content[session].elements.ics.value.length">
                                <div class="icon"></div>
                                <span class="ml-2"><a :href="modular_content[session].elements.ics.value[0].url" target="_blank" class="text-primary-500 hover:text-white transition-all">Add to calendar</a></span>
                              </div>
                            </div>
                          </div>
                          <div class="main-text mt-16 description" v-html="modular_content[session].elements.description.value"></div>

                          <div class="speakers mt-8">
                            <div class="font-bold presented-by">
                              Presented by
                            </div>
                            <div class="list mt-6 gap-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">


                              <div class="speaker">
                                <div class="avatar relative w-full">
                                  <img :src="`${modular_content[session].elements.presented_by_avatar.value[0].url}?w=340&auto=format`" class="absolute left-0 top-0 w-full h-full object-cover">
                                </div>
                                <div class="text mt-4">
                                  {{ modular_content[session].elements.presented_by_name.value }}
                                </div>
                              </div>


                              <div class="speaker" v-if="modular_content[session].elements.presented_by_name_2.value">
                                <div class="avatar relative w-full">
                                  <img :src="`${modular_content[session].elements.presented_by_avatar_2.value[0].url}?w=340&auto=format`" class="absolute left-0 top-0 w-full h-full object-cover">
                                </div>
                                <div class="text mt-4">
                                  {{ modular_content[session].elements.presented_by_name_2.value }}
                                </div>
                              </div>


                              <div class="speaker" v-if="modular_content[session].elements.presented_by_name_3.value">
                                <div class="avatar relative w-full">
                                  <img :src="`${modular_content[session].elements.presented_by_avatar_3.value[0].url}?w=340&auto=format`" class="absolute left-0 top-0 w-full h-full object-cover">
                                </div>
                                <div class="text mt-4">
                                  {{ modular_content[session].elements.presented_by_name_3.value }}
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>

                    </bleed-container>
                  </div>
                </div>
              </div>
            </div>

            <aside
                class="mb-16 md:mb-0 block md:sticky md:top-40 lg:top-60 md:w-96 lg:w-380 flex-shrink-0 md:ml-16 lg:ml-24 xl:ml-56">
              <bleed-container bleed="right">
                <div class="bg-grey-100 p-8 lg:p-16">
                  <div class="bleed-wrapper">
                    <div>
                      <div class="opacity-50">Date</div>
                      <div class="item flex mt-4">
                        <div class="icon shrink-0 w-10 mt-px">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.6875 1.8125V2.75H8.4375V1.8125C8.4375 1.31445 8.84766 0.875 9.375 0.875C9.87305 0.875 10.3125 1.31445 10.3125 1.8125V2.75H11.7188C12.4805 2.75 13.125 3.39453 13.125 4.15625V5.5625H0V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.8125C2.8125 1.31445 3.22266 0.875 3.75 0.875C4.24805 0.875 4.6875 1.31445 4.6875 1.8125ZM0 6.5H13.125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V6.5ZM1.875 9.78125C1.875 10.0449 2.08008 10.25 2.34375 10.25H3.28125C3.51562 10.25 3.75 10.0449 3.75 9.78125V8.84375C3.75 8.60938 3.51562 8.375 3.28125 8.375H2.34375C2.08008 8.375 1.875 8.60938 1.875 8.84375V9.78125ZM5.625 9.78125C5.625 10.0449 5.83008 10.25 6.09375 10.25H7.03125C7.26562 10.25 7.5 10.0449 7.5 9.78125V8.84375C7.5 8.60938 7.26562 8.375 7.03125 8.375H6.09375C5.83008 8.375 5.625 8.60938 5.625 8.84375V9.78125ZM9.84375 8.375C9.58008 8.375 9.375 8.60938 9.375 8.84375V9.78125C9.375 10.0449 9.58008 10.25 9.84375 10.25H10.7812C11.0156 10.25 11.25 10.0449 11.25 9.78125V8.84375C11.25 8.60938 11.0156 8.375 10.7812 8.375H9.84375ZM1.875 13.5312C1.875 13.7949 2.08008 14 2.34375 14H3.28125C3.51562 14 3.75 13.7949 3.75 13.5312V12.5938C3.75 12.3594 3.51562 12.125 3.28125 12.125H2.34375C2.08008 12.125 1.875 12.3594 1.875 12.5938V13.5312ZM6.09375 12.125C5.83008 12.125 5.625 12.3594 5.625 12.5938V13.5312C5.625 13.7949 5.83008 14 6.09375 14H7.03125C7.26562 14 7.5 13.7949 7.5 13.5312V12.5938C7.5 12.3594 7.26562 12.125 7.03125 12.125H6.09375ZM9.375 13.5312C9.375 13.7949 9.58008 14 9.84375 14H10.7812C11.0156 14 11.25 13.7949 11.25 13.5312V12.5938C11.25 12.3594 11.0156 12.125 10.7812 12.125H9.84375C9.58008 12.125 9.375 12.3594 9.375 12.5938V13.5312Z" fill="#E8490F"/>
                          </svg>
                        </div>
                        <div class="text">
                          {{dayjs.utc(day.elements.date.value).tz(day.elements.date.display_timezone).format('DD MMMM YYYY')}}
                          <div v-if="day.elements.ics.value.length">
                            <a :href="day.elements.ics.value[0].url" target="_blank" class="text-primary-500 hover:text-white transition-all">Add to calendar</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-12">
                      <div class="opacity-50">Location</div>
                      <div class="item flex mt-4">
                        <div class="icon shrink-0 w-10 mt-1">
                          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.25 6.5C11.25 9.07812 7.82227 13.6191 6.29883 15.5234C5.94727 15.9629 5.27344 15.9629 4.92188 15.5234C3.39844 13.6191 0 9.07812 0 6.5C0 3.39453 2.49023 0.875 5.625 0.875C8.73047 0.875 11.25 3.39453 11.25 6.5Z" fill="#E8490F"/>
                          </svg>
                        </div>
                        <div class="text">
                          {{page.elements.location.value}}<br>
                          {{page.elements.city.value}}, {{page.elements.country.value}}
                        </div>
                      </div>
                    </div>
                    <div class="mt-12" v-if="page.elements.website.value.length">
                      <div class="opacity-50">Website</div>
                      <div class="item flex mt-4">
                        <div class="icon shrink-0 w-10 mt-1">
                          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 11.1875V7.4375H11.25V11.1875C11.25 13.7949 9.14062 15.875 6.5625 15.875H4.6875C2.08008 15.875 0 13.7949 0 11.1875ZM5.15625 0.875V6.5H0V5.5625C0 2.98438 2.08008 0.875 4.6875 0.875H5.15625ZM6.5625 0.875C9.14062 0.875 11.25 2.98438 11.25 5.5625V6.5H6.09375V0.875H6.5625Z" fill="#E8490F"/>
                          </svg>
                        </div>
                        <div class="text">
                          <a :href="page.elements.website.value" target="_blank">{{page.elements.website.value}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="mt-12">
                      <div class="opacity-50">Social</div>
                      <div class="item flex mt-4 gap-4">

                        <a class="text-34 text-white mr-2 block" :href="page.elements.linkedin_url.value" target="_blank" v-if="page.elements.linkedin_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="currentColor"/></svg>
                        </div>

                        <a class="text-34 text-white mr-2 block" :href="page.elements.twitter_url.value" target="_blank" v-if="page.elements.twitter_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" fill="currentColor"/></svg>
                        </div>

                        <a class="text-34 text-white mr-2 block" :href="page.elements.facebook_url.value" target="_blank" v-if="page.elements.facebook_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" fill="currentColor"/></svg>
                        </div>

                        <a class="text-34 text-white mr-2 block" :href="page.elements.instagram_url.value" target="_blank" v-if="page.elements.instagram_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="currentColor"/></svg>
                        </div>

                      </div>
                    </div>


                    <div class="mt-16" v-if="page.elements.attend_event_url.value.length">
                      <a :href="page.elements.attend_event_url.value" target="_blank" class="btn-white inline-block">Attend event</a>
                    </div>
                  </div>
                </div>
              </bleed-container>
            </aside>
          </div>
        </div>

      </div>
    </div>

    <!-- full page starts here -->

    <div class="outer-container">
      <div class="inner-container mb-40">

        <div class="program mt-48" v-if="days.length">
          <h2 class="font-bold uppercase">See the other days</h2>

          <template v-for="(day, index) in days">
            <div class="program-item" :key="day.system.id">
              <bleed-container :bleed="index % 2 === 0 ? 'left' : 'right'" class="mt-24 relative">
                <div class="image relative h-80 md:absolute md:h-auto md:inset-1 z-10" v-if="day.elements.image.value.length">
                  <img :src="`${day.elements.image.value[0].url}?auto=format`" alt="" class="absolute left-0 top-0 w-full h-full object-cover">
                </div>
                <div class="bleed-wrapper relative z-20">
                  <div class="bg-black py-12 md:py-40" :class="{'md:ml-11/20 md:pl-12': index % 2 === 0, 'md:mr-11/20 md:pr-12': index % 2 !== 0}">
                    <h4 class="font-bold">Day {{day.elements.day_number.value}}: {{dayjs.utc(day.elements.date.value).tz(day.elements.date.display_timezone).format('ddd DD MMMM h:mma')}}</h4>
                    <div class="local-time main-text mt-4" v-if="dayjs.utc(day.elements.date.value).tz(day.elements.date.display_timezone).format('ddd DD MMMM h:mma') !== dayjs.utc(day.elements.date.value).local().format('ddd DD MMMM h:mma')">{{dayjs.utc(day.elements.date.value).local().format('ddd DD MMMM h:mma')}} ({{dayjs.tz.guess()}})</div>
                    <div class="ics" v-if="day.elements.ics.value.length">
                      <a :href="day.elements.ics.value[0].url" target="_blank" class="text-primary-500 hover:text-white transition-colors flex flex-row items-center mt-4">
                        <svg class="block mr-2 -mt-1" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.6875 1.8125V2.75H8.4375V1.8125C8.4375 1.31445 8.84766 0.875 9.375 0.875C9.87305 0.875 10.3125 1.31445 10.3125 1.8125V2.75H11.7188C12.4805 2.75 13.125 3.39453 13.125 4.15625V5.5625H0V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.8125C2.8125 1.31445 3.22266 0.875 3.75 0.875C4.24805 0.875 4.6875 1.31445 4.6875 1.8125ZM0 6.5H13.125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V6.5ZM1.875 9.78125C1.875 10.0449 2.08008 10.25 2.34375 10.25H3.28125C3.51562 10.25 3.75 10.0449 3.75 9.78125V8.84375C3.75 8.60938 3.51562 8.375 3.28125 8.375H2.34375C2.08008 8.375 1.875 8.60938 1.875 8.84375V9.78125ZM5.625 9.78125C5.625 10.0449 5.83008 10.25 6.09375 10.25H7.03125C7.26562 10.25 7.5 10.0449 7.5 9.78125V8.84375C7.5 8.60938 7.26562 8.375 7.03125 8.375H6.09375C5.83008 8.375 5.625 8.60938 5.625 8.84375V9.78125ZM9.84375 8.375C9.58008 8.375 9.375 8.60938 9.375 8.84375V9.78125C9.375 10.0449 9.58008 10.25 9.84375 10.25H10.7812C11.0156 10.25 11.25 10.0449 11.25 9.78125V8.84375C11.25 8.60938 11.0156 8.375 10.7812 8.375H9.84375ZM1.875 13.5312C1.875 13.7949 2.08008 14 2.34375 14H3.28125C3.51562 14 3.75 13.7949 3.75 13.5312V12.5938C3.75 12.3594 3.51562 12.125 3.28125 12.125H2.34375C2.08008 12.125 1.875 12.3594 1.875 12.5938V13.5312ZM6.09375 12.125C5.83008 12.125 5.625 12.3594 5.625 12.5938V13.5312C5.625 13.7949 5.83008 14 6.09375 14H7.03125C7.26562 14 7.5 13.7949 7.5 13.5312V12.5938C7.5 12.3594 7.26562 12.125 7.03125 12.125H6.09375ZM9.375 13.5312C9.375 13.7949 9.58008 14 9.84375 14H10.7812C11.0156 14 11.25 13.7949 11.25 13.5312V12.5938C11.25 12.3594 11.0156 12.125 10.7812 12.125H9.84375C9.58008 12.125 9.375 12.3594 9.375 12.5938V13.5312Z" fill="currentColor"/>
                        </svg>
                        Add to calendar
                      </a>
                    </div>
                    <div class="summary mt-6" v-if="day.elements.summary.value.length > 20" v-html="day.elements.summary.value"></div>
                    <div class="cta">
                      <a class="cta mt-6 text-14 flex flex-row items-center text-grey-400 uppercase group hover:text-grey-500 transition-all" :href="`/experience/${page.elements.slug.value}/day${day.elements.day_number.value}`">
                        Read more
                        <svg class="w-8 h-4 block ml-4 group-hover:ml-6 transition-all" width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect y="5.11523" width="18.8679" height="0.769231" fill="currentColor"/>
                          <rect width="6.85808" height="0.762008" transform="matrix(0.700341 -0.713809 0.700341 0.713809 14.5605 9.85156)" fill="currentColor"/>
                          <rect width="6.85808" height="0.762008" transform="matrix(-0.700341 -0.713809 0.700341 -0.713809 19.3638 6.04395)" fill="currentColor"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </bleed-container>
            </div>
          </template>
        </div>

        <div class="back mt-40 text-center">
          <router-link :to="`/experience/${page.elements.slug.value}`" class="btn-red-outline">
            Back to main event
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Analytics from '@/analytics'
import Kontent from "@/kontent";
import BleedContainer from "../components/BleedContainer.vue";
import VueMasonry from 'vue-masonry-css'
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.use(VueMasonry);
export default {
  name: 'ExperienceEventDay',
  metaInfo() {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {
        property: 'og:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {
        name: 'title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {
        name: 'description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      },
      {name: 'twitter:card', content: 'summary_large_image'},
      {
        name: 'twitter:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {
        name: 'twitter:description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      }
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {BleedContainer},
  props: {},
  data() {
    return {
      page: null,
      modular_content: null,
      day: null
    }
  },
  mounted() {
    Analytics.trackPage('Experience Event Day')
    if (this.$auth.isAuthenticated) {
      this.$store.dispatch("getFavouriteProducts")
      this.$store.dispatch("getFavouriteWineries")
    }

    const self = this
    Kontent.getExperienceEvent(this.$route.params.event).then((res) => {
      self.page = res.data.items[0]
      self.modular_content = res.data.modular_content

      for (let i = 0; i < self.page.elements.days.value.length; i++) {
        const d = this.page.elements.days.value[i];
        console.log('day number val ' + self.modular_content[d].elements.day_number.value)
        if (self.modular_content[d].elements.day_number.value.toString() === self.$route.params.day) {
          self.day = self.modular_content[d]
        }
      }

      setTimeout(function () {
        window.prerenderReady = true
      }, 1000)
    })

  },
  methods: {},
  computed: {
    dayjs() {
      return dayjs
    },
    days () {
      let d = []
      for (let i = 0; i < this.page.elements.days.value.length; i++) {
        const day = this.modular_content[this.page.elements.days.value[i]];
        if (day.elements.day_number.value.toString() !== this.$route.params.day)
        {
          d.push(day)
        }
      }
      return d
    },
  }
}
</script>

<style lang="scss">

  .sessions {
    .session {
      .description {
        a {
          color: #E8490F;
          transition: color 0.3s ease;
          &:hover {
            color: #FFF;
          }
        }
        p {
          margin: 15px 0;
        }
        h1, h2, h3, h4, h5 {
          margin-bottom: 20px;
          margin-top: 40px;
          font-weight: bold;
          color: #FFF;
        }
        ul {
          margin: 20px 0;
          padding-left: 15px;

          > li {
            list-style-type: disc;
          }
        }
        ol {
          margin: 20px 0;
          padding-left: 15px;

          > li {
            list-style-type: decimal;
          }
        }
      }
    }
  }

</style>
<style scoped lang="scss">
.hero-image-height {
  height: 80px;

  @media(min-width: 768px) {
    height: 10px;
  }

  @media(min-width: 1024px) {
    height: 30px;
  }
}

.page {
  line-height: 1.6;
}

.intro-text {
  line-height: 1.6;
  font-size: 20px;
  font-size: responsive 20px 22px;
  font-range: 768px 1220px;
  font-weight: bold;
}

.exhibitor-name {
  font-size: 16px;
  color: #E8490F;
}

.main-text {
  color: #9A9A9A;
}

.scroller {
  max-height: 550px;
}

.sessions {
  .session {
    background-color: #121212;
  }

  .avatar {
    flex-shrink: 0;
    aspect-ratio: 1/1;
    max-width: 170px;
  }

  .presented-by {
    font-size: 16px;
    line-height: 1.4;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .detail-block {
    font-size: 14px;
    .icon {
      width: 16px;
      flex-shrink: 0;
    }
  }
}
</style>
